var exports = {};

exports = function (hljs) {
  var NIX_KEYWORDS = {
    keyword: "rec with let in inherit assert if else then",
    literal: "true false or and null",
    built_in: "import abort baseNameOf dirOf isNull builtins map removeAttrs throw " + "toString derivation"
  };
  var ANTIQUOTE = {
    className: "subst",
    begin: /\$\{/,
    end: /}/,
    keywords: NIX_KEYWORDS
  };
  var ATTRS = {
    begin: /[a-zA-Z0-9-_]+(\s*=)/,
    returnBegin: true,
    relevance: 0,
    contains: [{
      className: "attr",
      begin: /\S+/
    }]
  };
  var STRING = {
    className: "string",
    contains: [ANTIQUOTE],
    variants: [{
      begin: "''",
      end: "''"
    }, {
      begin: "\"",
      end: "\""
    }]
  };
  var EXPRESSIONS = [hljs.NUMBER_MODE, hljs.HASH_COMMENT_MODE, hljs.C_BLOCK_COMMENT_MODE, STRING, ATTRS];
  ANTIQUOTE.contains = EXPRESSIONS;
  return {
    aliases: ["nixos"],
    keywords: NIX_KEYWORDS,
    contains: EXPRESSIONS
  };
};

export default exports;